import { Button } from "@mui/material";

export const Controls = ({ onQuerySubmit, onQueryAbort, isSendingQuery }: { onQuerySubmit: () => void; onQueryAbort: () => void; isSendingQuery: boolean; }) => {
    const buttonCss = { fontSize: 'larger', margin: '0 10px' }
    return (
        <div style={{ textAlign: 'right' }}>
            <Button variant="outlined" disabled={!isSendingQuery} style={buttonCss} onClick={() => onQueryAbort()}>生成中止</Button>
            <Button variant="custom" disabled={isSendingQuery} style={buttonCss} onClick={() => onQuerySubmit()}>AI商品説明を生成</Button>
        </div>
    )
}
