import { Backdrop, Box, Button, Card } from "@mui/material";

const LOGO_SRC = `${process.env.PUBLIC_URL}/assets/カラーミーアプリアイコン.png`;

export const LoginForm = ({ onLoginClick, disabled }: { onLoginClick: () => void; disabled: boolean; }) => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
        >
            {
              /* Buttonのみ */
              /*<Button variant="custom" disabled={disabled} onClick={onLoginClick}>ログイン</Button>*/
            }

            <Card sx={{ margin: '0 auto', padding: '40px', '> *': { textAlign: 'center' } }}>
                <Box sx={{ maxWidth: '128px', margin: '0 auto' }}>
                    <img src={LOGO_SRC} alt="AIでスペシャリストな商品説明自動生成"></img>
                </Box>
                <Box sx={{ margin: '20px 0' }}>
                    <p>
                        本アプリを利用するには、<br />
                        カラーミーショップアカウントによるログインが必要です。
                    </p>
                </Box>
                <Box sx={{ margin: '20px 0px 0px 0px' }}>
                    <Button variant="custom" sx={{ fontSize: 'larger', padding: '10px 40px' }} disabled={disabled} onClick={onLoginClick}>ログイン</Button>
                </Box>
            </Card>
        </Backdrop>
    )
}