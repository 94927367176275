import Tooltip from "@mui/material/Tooltip";
import { Template } from "../../common/types";
import { Typography } from "@mui/material";

export const TemplateSelectorItem = ({ selected, data }: { selected: boolean; data: Template }) => {
    const { icon, name, template, introduction, title } = data
    // const { age, orientation } = template.
    // float leftを使う限り（css gridも使ってもよい）、選択状態関係なく、ボーダーも含めてサイズは一緒にしないとレイアウトが崩れる。
    const additionalStyles = selected ? { /* opacity: '0.7' */ borderStyle: 'solid', backgroundColor: 'lightpink' } : { borderStyle: 'solid', borderColor: 'rgba(255,255,255,0)' }
    // const iconPath = `/assets/${icon}`
    const iconPath = process.env.PUBLIC_URL + `/assets/${icon}`
    const IMAGE_SIZE = {
        // width: 64,
        width: 128,
        // height: 64,
        height: 128,
    } as const;
    return (
        <Tooltip title={introduction || ''} placement="top-end">
            <div style={{ padding: '5px', cursor: 'pointer', borderRadius: '5px', borderColor: 'black', borderWidth: '1px', ...additionalStyles }}>
                <div style={{ padding: '5px', width: '128px' }}>
                    <Typography variant="h6" style={{ fontSize: 'small' }}>{ title || '' }</Typography>
                </div>
                <div style={{ padding: '5px', display: 'block', margin: 'auto', textAlign: 'center', width: `${IMAGE_SIZE.width}px`, height: `${IMAGE_SIZE.height}px` }}>
                    <img src={iconPath} alt={name} style={{}} />
                </div>
                <span style={{ fontSize: 'smaller' }}>{name}</span>
                {
                    /*
                    <span>{age}</span><br />
                    <span>{orientation}</span>
                    */
                }
            </div>
        </Tooltip>
    )
}
