import React from "react"
import { ColorMeProduct } from "../../common/types"
import { Autocomplete, FormControl, InputLabel, MenuItem, Popper, Select, SelectChangeEvent, TextField } from "@mui/material";
import { ALLOW_SELECT_NO_PRODUCT } from "../constants";
import { CommonInputLabel } from "./CommonInputLabel";

export const ProductSelector = ({ selected, items, onChange }: { selected: number|null; items: ColorMeProduct[], onChange: (id: number|null) => void }) => {
    // const onSelectChange: React.ChangeEventHandler<HTMLSelectElement> = (ev) => {
    /*
    const onSelectChange = (ev: SelectChangeEvent) => {
        const value = Number(ev.target.value)
        onChange(value)
    }
    */
    // https://mui.com/material-ui/api/autocomplete/#props
    // const onAutocompleteChange = <T, >(event: React.SyntheticEvent, value: T | Array<T>, reason: string, details?: any) => {
    const onAutocompleteChange = <T, >(event: React.SyntheticEvent, value: any, reason: string, details?: any) => {
        console.debug('onAutoCompleteChange', { event, value, reason, details })
        if (value) {
            const numValue = Number((value as { label: string; value: number}).value)
            // const numValue = Number(event.target.value)
            onChange(numValue)
        } else {
            if (ALLOW_SELECT_NO_PRODUCT) {
                onChange(null)
            }
        }
    }
    // const value = selected ? String(selected) : undefined
    const value = selected ? selected : undefined
    const SELECTOR_ID = 'product_selector'
    const autoCompleteOptions = items.map(item => ({ label: item.name, value: item.id }))
    const autoCompleteValue = autoCompleteOptions.filter(i => i.value === selected)[0] || null
    const MIN_WIDTH = '200px'
    const AUTO_COMPLETE_RENDER_INPUT_SX = { minWidth: MIN_WIDTH, 'input[type=text]': { minWidth: `${MIN_WIDTH}px!important` } }
    const MESSAGES = {
        LABEL: '商品選択',
        PLACEHOLDER: '商品名を入力してください',
        NO_OPTIONS: '選択肢がありません',
    }
    // const TEXT_LABEL_PROPS = { label: MESSAGES.LABEL } // With label
    const TEXT_LABEL_PROPS = { hiddenLabel: true } // Without label

    // 選択肢の最大幅を表示
    /**
     * @see https://stackoverflow.com/a/63583835/1764521
     */
    const styles = {
        popper: {
            width: "fit-content"
        }
    };
    const PopperMy = function (props: any[]) {
        // @ts-ignore
        return <Popper {...props} style={styles.popper} placement="bottom-start" />;
    } as any;

    return (
        <div style={{ width: '100%', textAlign: 'left' }}>
            <FormControl variant="standard" sx={{ minWidth: MIN_WIDTH, width: '100%' }}>
                {
                    items.length > 0 ? (
                        <>
                            {
                            /*
                            <InputLabel id={SELECTOR_ID}>商品選択</InputLabel>
                            */
                            }
                            {
                            /*
                            <Select onChange={onSelectChange} value={value} labelId={SELECTOR_ID}>
                                {
                                    items.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                                }
                            </Select>
                            */
                            }
                            <CommonInputLabel label={MESSAGES.LABEL}>
                                <Autocomplete
                                    PopperComponent={PopperMy}
                                    disablePortal
                                    id={SELECTOR_ID}
                                    options={autoCompleteOptions}
                                    renderInput={
                                        (params) => 
                                        <TextField
                                            {...params}
                                            {...TEXT_LABEL_PROPS}
                                            sx={AUTO_COMPLETE_RENDER_INPUT_SX}
                                            placeholder={MESSAGES.PLACEHOLDER}
                                            InputProps={{
                                                ...params.InputProps,
                                                placeholder: !value ? MESSAGES.PLACEHOLDER : "",
                                            }}
                                            title={`${params.inputProps.value}` || ''}
                                        />}
                                    value={autoCompleteValue}
                                    onChange={onAutocompleteChange}
                                    noOptionsText={MESSAGES.NO_OPTIONS}
                                />
                            </CommonInputLabel>
                        </>
                    ) : <></>
                }
            </FormControl>
        </div>
    )
}
