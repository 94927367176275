// import logo from './logo.svg';
import "@fontsource/noto-sans-jp"; // Defaults to weight 400. // https://www.npmjs.com/package/@fontsource/noto-sans-jp
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ProductSelector } from './components/ProductSelector';
import React from "react"
import { DetailedHTMLProps, HTMLAttributes, useState, useEffect } from 'react';
import { API } from './api/API';
import { MockAPI } from './mock/MockAPI';
import { ColorMeProduct, AIQuery, ColorMeProductWithLinkedData, Template, UserInfo } from '../common/types';
import { getEnvironmentVariables, parseBooleanString, preventInfiniteReload } from './helpers';
// import { toast, ToastContainer } from 'react-toastify';
import { toast, CommonToastContainer } from "./components/CommonToastContainer";
import { Backdrop, Button, ThemeProvider } from "@mui/material";
import { isCurrentOrigin, preventResizeObserverLoopError } from "./utilities";
import { attemptingAuthorizationState } from "./state";
import { theme as myTheme } from './theme'
import { AUTO_SELECT_PRODUCT } from "./constants";
import { FullScreenLoader } from "./components/FullScreenLoader";
import { Header } from "./components/Header";
// import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { copyToClipboard } from "./utilities";
import { Box } from "@mui/material";
import { AdditionalInput } from "./components/AdditionalInput";
import { Controls } from "./components/Controls";
import { Loader } from "./components/Loader";
import { ProductInfo } from "./components/ProductInfo";
import { QueryResponse } from "./components/QueryResponse";
import { TemplateSelectionContainer } from "./components/TemplateSelectionContainer";
import { LoginForm } from "./components/LoginForm";
import { CreateChatCompletionResponse } from "openai";

const preventInfiniteReloadWithUI = () => {
  try {
    preventInfiniteReload()
  } catch (err) {
    toast.error('ナビゲーションを中止した')
    throw err
  }
}

const onLoggedOut = () => {
  console.debug('onLoggedOut')
  preventInfiniteReloadWithUI()
  window.location.reload()
}

const {
  API_URL, MOCK_API,
} = getEnvironmentVariables(['API_URL', 'MOCK_API'])
const apiParams = {
  base: API_URL,
  onLoggedOut,
}
console.debug({ API_URL, MOCK_API, })
const api = parseBooleanString(MOCK_API) ? new MockAPI(apiParams) : new API(apiParams)

function App() {
  console.debug('App update')
  const [products, setProducts] = useState<ColorMeProduct[]>([])
  const [selectedProduct, setSelectedProduct] = useState<number|null>(null)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [user, setUser] = useState<UserInfo|null>(null)

  /**
   * 開発でのhook使い方間違いで更新により大量開く現象を防ぐため。
   * global(window) stateにより、非同期中の実行によるstate問題を防ぐ（2回表示する場合ある問題）。
   * popupが表示しない環境もあり得る。ユーザー動作（クリック）からの場合、表示しない理由が減ります。
   * 
   * ※ ログインボタンがあれば、この問題は完全になくなります。既に実装済み。
   */
  // const [isAttemptingAuthorization, setIsAttemptingAuthorization] = useState<boolean>(false)
  // const [isAttemptingAuthorization, setIsAttemptingAuthorization] = attemptingAuthorizationState.use();
  const [isAttemptingAuthorizationHook, setIsAttemptingAuthorizationHook] = attemptingAuthorizationState.use();
  const isAttemptingAuthorization = () => {
    // return isAttemptingAuthorizationHook
    return (window as any)['isAttemptingAuthorization'] || false
  }
  const setIsAttemptingAuthorization = (bool: boolean, callback?: (newState: boolean) => void) => {
    (window as any)['isAttemptingAuthorization'] = bool;
    setIsAttemptingAuthorizationHook(bool, callback)
  }
  
  function refreshProducts() {
    api.getProducts().then(r => {
      const products = r.products as ColorMeProduct[]
      setProducts(products)
      if (AUTO_SELECT_PRODUCT) {
        setSelectedProduct((products.length > 0 ? products[0].id : null) || null)
      } else {

      }
    }).catch(error => {
      console.warn('getProducts FAILURE', error)
      toast.error('商品一覧取得に失敗した。', error)
    })
  }

  function refreshUser() {
    api.getUser().then(r => {
      setUser(r.user)
    }).catch(error => {
      console.warn('getUser FAILURE', error)
      toast.error('ユーザー情報取得に失敗した。', error)
    })
  }
  
  console.debug({
    PUBLIC_URL: getEnvironmentVariables(['PUBLIC_URL']).PUBLIC_URL,
    pathname: window.location.pathname,
  })

  const checkIsLoggedIn = async() => {
    let isLoggedIn = false
    try {
      isLoggedIn = await api.checkIsLoggedIn()
    } catch (err) {
      const ERROR = 'ログインチェックが失敗した'
      toast.error(ERROR)
      throw new Error(ERROR)
    }

    return isLoggedIn
  }
  const autoLoginEnabled = ((new URL(window.location.href)).searchParams.has('disableAutoLogin')) ? false : true

  /**
   * 別画面でログイン画面が表示。
   * ログイン後のリダイレクトは、開いたページがこのページをアクセスして実行する。
   */
  async function showLogin() {
    console.debug('showLogin', { isAttemptingAuthorization: isAttemptingAuthorization() })
    const { OAUTH_URI } = getEnvironmentVariables(['OAUTH_URI'])

    if (isLoggedIn) return
    if (isAttemptingAuthorization()) return
    
    const newIsAttemptingAuthorization = await new Promise(resolve => setIsAttemptingAuthorization(true, resolve)); console.debug({ newIsAttemptingAuthorization });
    const onIsAttemptingAuthorizationEnd = async () => {
      const isLoggedIn = await checkIsLoggedIn()
      await new Promise(resolve => setIsAttemptingAuthorization(false, resolve))
      if (isLoggedIn) {
        console.debug('showLogin reload')
        preventInfiniteReloadWithUI()
        const url = new URL(window.location.href); url.searchParams.delete('disableAutoLogin'); window.location.href = url.toString();
        // window.location.reload()
      } else {
        toast.error('ログインに失敗しました。再びログインを試すには、ページを更新してください。')
      }
    }
    // alert(OAUTH_URI) // DEBUG対応
    if (isCurrentOrigin(OAUTH_URI)) {
      console.error('OAuthのURIが同じoriginであるため、認証による無限ループを防ぐため、認証画面を開かなった。')
      return
    }
    const w = openPopupWindow(OAUTH_URI)
    if (w) {
      onWindowClose(w, onIsAttemptingAuthorizationEnd)
    } else {
      console.warn('Window failed to open')
    }

    return w
  }  
  
  useEffect(() => {
    console.debug({ isLoggedIn })
    if (isLoggedIn) {
      refreshProducts()
      refreshUser()
    } else {
      setUser(null)
      if (autoLoginEnabled) {
        checkIsLoggedIn()
        .then((_isLoggedIn) => {
          if (_isLoggedIn) {
            setIsLoggedIn(true)
          } else {
            // 自動ログインポップアップ表示
            // return showLogin()
          }
        })
      }
    }
  }, [isLoggedIn])

  const [templates, setTemplates] = useState<Template[]>([])
  const [templateId, setTemplateId] = useState<string|null>(null)
  const [additionalInput, setAdditionalInput] = useState('')
  const [result, setResult] = useState('')
  const [queryAbortController, setQueryAbortController] = useState<AbortController|null>(null)
  const [productInfo, setProductInfo] = useState<ColorMeProductWithLinkedData|null>(null)
  const [queryStatusText, setQueryStatusText] = useState<string | null>(null)

  // Loading
  const [isLoadingTemplates, setIsLoadingTemplates] = useState<boolean>(false)
  const [isSendingQuery, setIsSendingQuery] = useState<boolean>(false)
  const [isUpdatingProduct, setIsUpdatingProduct] = useState<boolean>(false)

  // const theme = useTheme();
  const theme = myTheme;

  useEffect(() => {
    window.addEventListener('error', e => {
      preventResizeObserverLoopError(e)
    });
  }, []);

  const product: ColorMeProduct|undefined = products.filter(p => p.id === selectedProduct)[0];
  useEffect(() => {
    /*
    テンプレートは変動しないため、一回のみでよい。
    productが設定されるタイミングで実行。非設定ができないため、ほかの動作は必須でない。
    productが設定されていないタイミングで実行してもよい。※ product選択前で選択したい次第。
    */

    // テンプレート一覧
    if (!templateId && isLoggedIn) {
      setIsLoadingTemplates(true)
      api.getTemplates().then(t => {
          setTemplates(t)
          if (t.length > 0) {
              setTemplateId(t[0].id)
          }
      }).catch(error => {
          console.warn('getTemplates FAILURE', error)
          toast.error('テンプレート一覧取得に失敗した。', error)
      }).finally(() => setIsLoadingTemplates(false))
    }

    // 商品詳細
    if (product && product.id) {
        api.getProduct(product.id).then(t => {
            setProductInfo(t)
        }).catch(error => {
            console.warn('getProduct FAILURE', error)
            toast.error('商品取得に失敗した。', error)
        })
    }
  }, [isLoggedIn, product, templateId])
  const onTemplateChange = (template: Template) => {
      setTemplateId(template.id)
  }
  const onAdditionalInputChange = (additionalInput: string) => {
      setAdditionalInput(additionalInput)
  }
  const getQuery = () => {
      if (!product) throw new Error('No product')
      if (!templateId) throw new Error('No templateId')
      const query: AIQuery = {
          productId: Number(product.id),
          templateId,
          additionalInput,
      }
      return query
  }
  const setQueryResponseUI = (r: CreateChatCompletionResponse) => {
    // let responseText = r.choices[0]?.text || '' // Text Completion
    let responseText = r.choices[0]?.message?.content || '' // Chat Completion

    // 完了していない対応
    const notFinished = r.choices[0]?.finish_reason === 'length'
    if (notFinished) {
      responseText += '...'
      const QUERY_TEXT_LIMITED_WARNING = '結果が省略されました。'
      // const QUERY_TEXT_LIMITED_WARNING = '最大処理時間を超えたため、結果が省略されました。次のような追加入力で短くしてください：「結果を短くしてください。」'
      toast.warn(QUERY_TEXT_LIMITED_WARNING)
      // setQueryStatusText(QUERY_TEXT_LIMITED_WARNING)
    } else {
      // setQueryStatusText(null)
    }

    setResult(responseText)
  }
  const onQuerySubmit = () => {
      const query = getQuery()

      setIsSendingQuery(true)

      const controller = new AbortController()
      setQueryAbortController(controller)

      api.queryServer(query, controller).then(r => {
        setQueryResponseUI(r)
          
      }).catch(error => {
          console.warn('queryServer FAILURE', error)
          toast.error('AIによる商品説明の取得に失敗した。', error)
      }).finally(() => {
          setIsSendingQuery(false)
          setQueryAbortController(null)
      })
  }
  const onQueryAbort = () => {
      if (queryAbortController) {
          queryAbortController.abort()
      }
  }
  const onConfirm = () => {
    if (!product) throw new Error('No product')

    const query = getQuery()
    setIsUpdatingProduct(true)
    api.updateProductDescription(Number(product.id), query)
    .then(r => onUpdateDescription())
    .catch(error => {
        console.warn('updateProductDescription FAILURE', error)
        toast.error('商品説明の更新に失敗した。', error)
    })
    .finally(() => setIsUpdatingProduct(false))
  }
  const onCopyClick = () => {
      copyToClipboard(result).then(() => {
          toast.success('クリップボードに保存しました。', {})
      })
  }
  const { md } = theme.breakpoints.values
  const mq = `@media (min-width: ${md}px)`
  const block = { margin: '10px 0' } as DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  const INLINE_BLOCK = { float: 'none!important', display: 'inline-block!important', verticalAlign: 'top' }
  
  const fullWidthBlock = { display: 'flow-root', clear: 'both', width: '100%', ...block } as DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

  // Column Container
  const columnsBlock = { ...fullWidthBlock, width: 'auto', margin: '0 auto', textAlign: 'center' } as React.CSSProperties

  // 左 右
  const halfWidthBlock = { ...block, ...INLINE_BLOCK }
  const styleLeft = { [mq]: { float: 'left', maxWidth: '50%', padding: '0px 20px', boxSizing: 'border-box' }, ...halfWidthBlock } as DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  const styleRight = { [mq]: { float: 'left', minWidth: '50%', maxWidth: '50%', padding: '0px 20px', boxSizing: 'border-box' }, ...halfWidthBlock } as DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

  const onProductChange = (id: number|null) => {
    setSelectedProduct(id)
  }
  const onUpdateDescription = () => {
    refreshProducts()
  }

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {
          isLoggedIn ? <div className="App">

            <Header api={api} user={user} onLoggedOut={onLoggedOut} />

            <main style={{ margin: '10px', padding: '10px', maxWidth: '100%', minHeight: '90vh', overflowX: 'auto' }}>
              <section>
                {
                  /*
                <div>
                  <ProductSelector selected={selectedProduct} items={products} onChange={onProductChange} />
                </div>
                  */
                }

                {<div id="main-wrapper" style={{ /* border: '1px solid lightgray',*/ margin: '10px auto', display: 'inline-block' }}>
                  <div style={columnsBlock}>
                      <Box sx={{ ...styleLeft, minWidth: '460px' }}>
                        {
                          (isLoadingTemplates || templates.length > 0) ? <TemplateSelectionContainer isLoadingTemplates={isLoadingTemplates} onTemplateChange={onTemplateChange} templateId={templateId} templates={templates} /> : <></>
                        }

                        <div style={{ width: '100%' }}>
                          <div style={{ marginTop: '40px' }}>
                            <ProductSelector selected={selectedProduct} items={products} onChange={onProductChange} />
                          </div>

                          {product && <div style={{ marginTop: '10px' }}>
                              <ProductInfo data={product} info={productInfo} />
                            </div>
                          }
                        </div>
                      </Box>

                      <Box sx={{ ...styleRight }}>
                        {/*product && <ProductInfo data={product} info={productInfo} />*/}

                        {product && <div style={{ }}>
                            <AdditionalInput disabled={isSendingQuery} onChange={onAdditionalInputChange} />
                            { product && !isUpdatingProduct && <Controls onQuerySubmit={onQuerySubmit} onQueryAbort={onQueryAbort} isSendingQuery={isSendingQuery} /> }
                        </div>}

                        {product && <div style={fullWidthBlock}>
                          { (!isSendingQuery && !isUpdatingProduct) ? <QueryResponse value={result} onCopyClick={onCopyClick} onConfirmClick={onConfirm} statusText={queryStatusText} /> : <Box sx={{ display: 'flex', flexDirection: 'column', '> *': { 'alignSelf': 'self-end' } }}><Loader /></Box> }
                        </div>}
                      </Box>
                  </div>
                </div>}

              </section>
            </main>
          </div> : (
            <>
              {/*<FullScreenLoader></FullScreenLoader>*/}
              {
                <LoginForm onLoginClick={() => showLogin()} disabled={isAttemptingAuthorization()} />  
              }
            </>
          )
        }
        <CommonToastContainer />
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;

function onWindowClose(w: Window, onClose: () => void) {
  // w?.addEventListener('beforeunload', onClose)
  const interval = window.setInterval(() => {
    if (w.closed) {
      window.clearInterval(interval)
      onClose()
    }
  }, 1000)
}

function openPopupWindow(url: string) {
  // return window.open(url, '_blank')
  return window.open(url, "", "toolbar=no, menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no")
}
