import { toast, ToastContainer } from 'react-toastify';

export const CommonToastContainer = () => {
    return (
        <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
    )
};
export {
    toast
};
