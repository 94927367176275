import { Box, CircularProgress } from "@mui/material";

export const Loader = () => {
    return (
        <Box sx={{ display: 'flex', margin: '10px' }}>
            <CircularProgress />
            <div style={{ margin: '10px' }}>少々お待ちください</div>
        </Box>
    );
}
