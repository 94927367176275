import { ColorMeProduct } from "../../common/types";

/**
 * @see 
 */
export const parseColorMeProductInfo = (data: ColorMeProduct) => {
    const name = data.name || '';
    const description = data.memo || ''

    // 色
    /*
    const colors: string[] = [];
    ((data.options || []).filter(i => i.name === '色')[0]?.values || []).forEach(color => {
        if (!colors.includes(color)) {
            colors.push(color)
        }
    })
    */

    // サイズ
    //const sizes: string[] = [];
    /**
     * XXLもあったので、XXX まで追加したが、ほかもありそうなので、一応無効にした。
     */
    // const ALLOWED_SIZES = ['XXXS', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'];
    /*
    (data.variants || []).forEach(i => {
        [i.option1_value, i.option2_value].forEach(o => {
            if (o && !sizes.includes(o)) {
                sizes.push(o)
            }
        })
    });
    */

    const options = (data.options || []).map((option, index) => {
        return {
            key: option.id || index,
            name: option.name || '',
            values: option.values || [],
        }
    })
    const price = data.sales_price_including_tax || 0

    // 文字
    // const priceText = `${data.price || data.sales_price_including_tax || data.members_price_including_tax || 0}円（税込み）`;
    const priceText = `${(price).toLocaleString()}円（税込み）`;

    return {
        name,
        description,
        options,
        priceText,
    }
}
