import { Box, TextField } from "@mui/material"
import { FocusEventHandler } from "react"
import { CommonInputLabel } from "./CommonInputLabel"
import { RESIZABLE } from "../constants"

const RESIZABLE_SYNC_SIZE_WITH_TEXTAREA = { 'textarea': { height: '100%!important' } } as const;

export const AdditionalInput = ({ disabled, onChange }: { disabled?: boolean, onChange: (text: string) => void }) => {
    const onInputChange: React.ChangeEventHandler<HTMLTextAreaElement> = (ev) => {
        const text = ev.target.value
        onChange(text)
    }
    // const onInputBlur: React.ChangeEventHandler<HTMLTextAreaElement> = (ev) => {
    const onInputBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (ev) => {
        // const text = ev.target.value
        // onChange(text)
    }
    const TEXT_INPUT_STYLE = { margin: '5px 10px 5px 0px', minWidth: '320px', display: 'flex' } as React.CSSProperties
    return (
        <div style={{ padding: '10px 0' }}>
            <CommonInputLabel label="商品の説明">
                <TextField disabled={!!disabled} multiline rows={3} onBlur={onInputBlur} onChange={onInputChange} style={TEXT_INPUT_STYLE} InputProps={{ style: { ...RESIZABLE } }} sx={RESIZABLE_SYNC_SIZE_WITH_TEXTAREA} ></TextField>
            </CommonInputLabel>
        </div>
    )
}
