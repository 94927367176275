
export const TEST_TEMPLATE_DATA = {
    AGE: '40',
    GENDER: '男性',
    EXPERIENCE: '20',
    BUSINESS: '大手デパート',
    ROLE: '敏腕バイヤー',
    PRODUCT: 'ジミーチュウパンプス',
    PRICE: '25,000',
    SIZE: '7.1～10cm',
    COLOR: 'ゴールド',
}

export const TEST_TEMPLATES = [
    /*
    // OLD:
      {
          "id": "general-man",
          "name": "一般男性",
          "icon": "1.png",
          "template": {
              "id": "template",
              "parameters": {
                  "age": "30・40代",
                  "orientation": "一般向け"
              }
          }
      },
      {
          "id": "young-woman",
          "name": "若い女性",
          "icon": "2.png",
          "template": {
              "id": "template",
              "parameters": {
                  "age": "20・30代",
                  "orientation": "若者向け"
              }
          }
      },
      {
          "id": "young-woman-1",
          "name": "10年代女性1",
          "icon": "3.png",
          "template": {
              "id": "template",
              "parameters": {
                  "age": "10・20代",
                  "orientation": "奇抜系"
              }
          }
      },
      {
          "id": "young-woman-2",
          "name": "10年代女性2",
          "icon": "4.png",
          "template": {
              "id": "template",
              "parameters": {
                  "age": "10・20代",
                  "orientation": "奇抜系"
              }
          }
      },
      {
          "id": "all",
          "name": "年齢全般",
          "icon": "5.png",
          "template": {
              "id": "template",
              "parameters": {
                  "age": "年齢全般",
                  "orientation": "固い系"
              }
          }
      }
      */
    // NEW
    {
        "id": "charisma-staff",
        "name": "カリスマ店員",
        "icon": "カリスマショップ店員.png",
        "template": {
            "id": "template-new",
            "parameters": {
                "custom": "あなたは「20代渋谷109系のカリスマショップ店員」です。\nオンラインショップで商品を販売するために、20代渋谷109系のカリスマ店員というのを生かして、商品の説明文をかんがえます。\n（ただし、紹介文の中に「20代渋谷109系のカリスマショップ店員」という言葉は入れないでください）"
            }
        }
    },
    {
        "id": "product-buyer",
        "name": "商品バイヤー",
        "icon": "カリスマ食品バイヤー.png",
        "template": {
            "id": "template-new",
            "parameters": {
                "custom": "あなたは「カリスマの商品バイヤー」です。あらゆる商品を過去にセンスのある営業トークで売ってきました。\n                    今回は、オンラインショップで商品を販売するために、バイヤーの経験を生かして、商品の説明文をかんがえます。\n                    （ただし、紹介文の中に「20代渋谷109系のカリスマショップ店員」という言葉は入れないでください）"
            }
        }
    },
    {
        "id": "ai-assistant",
        "name": "AIアシスタント",
        "icon": "ai.png",
        "template": {
            "id": "template-new",
            "parameters": {
                "custom": "あなたは、オンラインショップのAIアシスタントです。\n商品の特性・カテゴリなどを分析して、最適な商品の説明文をかんがえます。"
            }
        }
    }
]

/**
 * @see https://platform.openai.com/docs/api-reference/completions
 */
const TEST_CHATGPT_TEXT_COMPLETION_API_RESPONSE = {
    "id": "cmpl-uqkvlQyYK7bGYrRHQ0eXlWi7",
    "object": "text_completion",
    "created": 1589478378,
    "model": "text-davinci-003",
    "choices": [
        {
            "text": "\n\nThis is indeed a test",
            "index": 0,
            "logprobs": null,
            "finish_reason": "length"
        }
    ],
    "usage": {
        "prompt_tokens": 5,
        "completion_tokens": 7,
        "total_tokens": 12
    }
}

/**
 * @see https://platform.openai.com/docs/api-reference/chat
 */
const TEST_CHATGPT_CHAT_COMPLETION_API_RESPONSE = {
    "id": "chatcmpl-123",
    "object": "chat.completion",
    "created": 1677652288,
    "choices": [{
        "index": 0,
        "message": {
            "role": "assistant",
            "content": "\n\nHello there, how may I assist you today?",
        },
        "finish_reason": "stop"
    }],
    "usage": {
        "prompt_tokens": 9,
        "completion_tokens": 12,
        "total_tokens": 21
    }
}

export const TEST_CHATGPT_API_RESPONSE = TEST_CHATGPT_CHAT_COMPLETION_API_RESPONSE

/**
 * @see https://developer.shop-pro.jp/docs/colorme-api#tag/product/operation/getProduct
 */
export const TEST_COLOR_ME_API_GET_PRODUCT_RESPONSE = {
    "product": {
        "account_id": "PA01234567",
        "id": 1342332,
        "name": "Tシャツ",
        "stocks": 20,
        "stock_managed": false,
        "few_num": 5,
        "model_number": "T-223",
        "category": {
            "id_big": 13223,
            "id_small": 18447
        },
        "group_ids": [
            5456,
            62098
        ],
        "display_state": "showing",
        "sales_price": 1980,
        "sales_price_including_tax": 2178,
        "sales_price_tax": 198,
        "price": null,
        "members_price": 1680,
        "members_price_including_tax": 1848,
        "members_price_tax": 168,
        "cost": null,
        "delivery_charge": null,
        "cool_charge": null,
        "unavailable_payment_ids": [
            600001,
            600002
        ],
        "unavailable_delivery_ids": [
            400001,
            400002
        ],
        "min_num": null,
        "max_num": null,
        "sale_start_date": null,
        "sale_end_date": null,
        "unit": null,
        "weight": null,
        "soldout_display": true,
        "sort": 2,
        "simple_expl": "素材にこだわってつくった、弊社の人気商品です。",
        "expl": "綿100%のこだわりTシャツです。\n\n肌触りや吸水性の良さにみなさま驚かれます。弊社の人気商品です。\n",
        "mobile_expl": null,
        "smartphone_expl": null,
        "make_date": 1465784934,
        "update_date": 1494496895,
        "memo": "",
        "image_url": "https://img07.shop-pro.jp/XXX/XX/product/123.jpg?20130319164314",
        "mobile_image_url": "https://img07.shop-pro.jp/XXX/XX/product/123_mb.jpg?20130319164314",
        "thumbnail_image_url": "https://img07.shop-pro.jp/XXX/XX/product/123_th.jpg?20130319164314",
        "images": [
            {
                "src": "string",
                "position": 1,
                "mobile": true
            }
        ],
        "options": [
            {
                "id": 539903,
                "product_id": 1342332,
                "account_id": "PA01234567",
                "name": "色",
                "values": [
                    "赤",
                    "白"
                ],
                "make_date": 1465784944,
                "update_date": 1494496809
            }
        ],
        "variants": [
            {
                "product_id": 1342332,
                "account_id": "PA01234567",
                "option1_value": "赤",
                "option2_value": "S",
                "title": "赤　x　S",
                "stocks": 20,
                "few_num": 5,
                "model_number": "T-RED-S-223",
                "option_price": 1980,
                "option_price_including_tax": 2178,
                "option_price_tax": 198,
                "option_members_price": 100,
                "option_members_price_including_tax": 110,
                "option_members_price_tax": 10,
                "make_date": 1465784944,
                "update_date": 1494496809
            }
        ],
        "pickups": [
            {
                "pickup_type": 0,
                "order_num": 0,
                "make_date": 1465784944,
                "update_date": 1494496809
            }
        ],
        "digital_conent": false,
        "regular_purchase": false,
        "tax_reduced": false,
        "without_shipping": false
    },
    category: {
        "id_big": 9923,
        "id_small": 0,
        "account_id": "PA01234567",
        "name": "Tシャツ",
        "image_url": "string",
        "expl": null,
        "sort": 2,
        "display_state": "showing",
        "make_date": 1465784944,
        "update_date": 1494496809,
        "children": []
    }
}

/**
 * @see https://developer.shop-pro.jp/docs/colorme-api#tag/product/operation/getProducts
 */
export const TEST_COLOR_ME_API_GET_PRODUCTS_RESPONSE = {
    "products": [
        {
            "account_id": "PA01234567",
            "id": 1342332,
            "name": "Tシャツ",
            "stocks": 20,
            "stock_managed": false,
            "few_num": 5,
            "model_number": "T-223",
            "category": {
                "id_big": 13223,
                "id_small": 18447
            },
            "group_ids": [
                5456,
                62098
            ],
            "display_state": "showing",
            "sales_price": 1980,
            "sales_price_including_tax": 2178,
            "sales_price_tax": 198,
            "price": null,
            "members_price": 1680,
            "members_price_including_tax": 1848,
            "members_price_tax": 168,
            "cost": null,
            "delivery_charge": null,
            "cool_charge": null,
            "unavailable_payment_ids": [
                600001,
                600002
            ],
            "unavailable_delivery_ids": [
                400001,
                400002
            ],
            "min_num": null,
            "max_num": null,
            "sale_start_date": null,
            "sale_end_date": null,
            "unit": null,
            "weight": null,
            "soldout_display": true,
            "sort": 2,
            "simple_expl": "素材にこだわってつくった、弊社の人気商品です。",
            "expl": "綿100%のこだわりTシャツです。\n\n肌触りや吸水性の良さにみなさま驚かれます。弊社の人気商品です。\n",
            "mobile_expl": null,
            "smartphone_expl": null,
            "make_date": 1465784934,
            "update_date": 1494496895,
            "memo": "",
            "image_url": "https://img07.shop-pro.jp/XXX/XX/product/123.jpg?20130319164314",
            "mobile_image_url": "https://img07.shop-pro.jp/XXX/XX/product/123_mb.jpg?20130319164314",
            "thumbnail_image_url": "https://img07.shop-pro.jp/XXX/XX/product/123_th.jpg?20130319164314",
            "images": [
                {
                    "src": "string",
                    "position": 1,
                    "mobile": true
                }
            ],
            "options": [
                {
                    "id": 539903,
                    "product_id": 1342332,
                    "account_id": "PA01234567",
                    "name": "色",
                    "values": [
                        "赤",
                        "白"
                    ],
                    "make_date": 1465784944,
                    "update_date": 1494496809
                }
            ],
            "variants": [
                {
                    "product_id": 1342332,
                    "account_id": "PA01234567",
                    "option1_value": "赤",
                    "option2_value": "S",
                    "title": "赤　x　S",
                    "stocks": 20,
                    "few_num": 5,
                    "model_number": "T-RED-S-223",
                    "option_price": 1980,
                    "option_price_including_tax": 2178,
                    "option_price_tax": 198,
                    "option_members_price": 100,
                    "option_members_price_including_tax": 110,
                    "option_members_price_tax": 10,
                    "make_date": 1465784944,
                    "update_date": 1494496809
                }
            ],
            "pickups": [
                {
                    "pickup_type": 0,
                    "order_num": 0,
                    "make_date": 1465784944,
                    "update_date": 1494496809
                }
            ],
            "digital_conent": false,
            "regular_purchase": false,
            "tax_reduced": false,
            "without_shipping": false
        }
    ],
    "meta": {
        "total": 300,
        "limit": 50,
        "offset": 200
    }
}

export const TEST_NOTIFICATIONS = [
    {
        "title": "Test Notification",
        "body": "Test Body",
        "id": 123,
        "start_date": Number(new Date('2022/05/28')),
        "end_date": Number(new Date('2022/12/12')),
        "created_date": Number(new Date()),
        "updated_date": Number(new Date()),
    }
]
