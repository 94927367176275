import { createTheme } from "@mui/material";
import { blue, grey } from "@mui/material/colors";

const theme = createTheme({
    components: {
      /**
       * @see https://mui.com/material-ui/customization/theme-components/#creating-new-component-variants
       */
      MuiButton: {
        variants: [
          {
            props: { variant: 'custom' },
            style: {
              textTransform: 'none',
              border: `2px dashed ${blue[500]}`,
              color: "white",
              backgroundColor: blue[500],
              ":hover": {
                backgroundColor: blue[700],
                borderStyle: 'solid'
              }
            },
          },
          {
            props: { variant: 'custom', disabled: true },
            style: {
                border: `2px dashed ${grey[200]}`,
                color: "white",
                backgroundColor: grey[200],
            },
          },
        ],
      },
    },
  });
export { theme }
