import { TEST_COLOR_ME_API_GET_PRODUCTS_RESPONSE, TEST_CHATGPT_API_RESPONSE, TEST_COLOR_ME_API_GET_PRODUCT_RESPONSE, TEST_TEMPLATES, TEST_NOTIFICATIONS } from "."
import { API } from "../api/API";
import { AIQuery, ColorMePUTProductBody, ColorMeGETProductSuccessResponse, ColorMeGETProductsSuccessResponse, ColorMePUTProductSuccessResponse, Template, OpenAICompletionsSuccessResponse, ColorMeProductWithLinkedData, ServiceNotification } from '../../common/types'

export class MockAPI extends API {
    async getProducts(): Promise<ColorMeGETProductsSuccessResponse> {
        return TEST_COLOR_ME_API_GET_PRODUCTS_RESPONSE as any
    }
    
    // async getProduct(id: number): Promise<ColorMeGETProductSuccessResponse> {
    async getProduct(id: number): Promise<ColorMeProductWithLinkedData> {
        return TEST_COLOR_ME_API_GET_PRODUCT_RESPONSE as any
    }
    
    async updateProductDescription(id: number, query: AIQuery): Promise<ColorMePUTProductSuccessResponse> {
        return {} as any
    }
    
    async queryServer(query: AIQuery): Promise<OpenAICompletionsSuccessResponse> {
        return TEST_CHATGPT_API_RESPONSE as any as OpenAICompletionsSuccessResponse
    }
    
    async getTemplates(): Promise<Template[]> {
        return TEST_TEMPLATES as any
    }

    async getNotifications(): Promise<ServiceNotification[]> {
        return TEST_NOTIFICATIONS as any
    }
}
