import { Button } from "@mui/material";
import { MultilineComponent } from "./MultilineComponent";
import { CommonInputLabel } from "./CommonInputLabel";
import { RESIZABLE } from "../constants";

const OUTPUT_CSS: React.CSSProperties = { display: 'block', padding: '5px', minWidth: '200px', border: '1px solid lightgray', borderRadius: '5px', textAlign: 'left', ...RESIZABLE }

export const QueryResponse = ({ value, onCopyClick, onConfirmClick, statusText }: { value: string; onCopyClick: () => void; onConfirmClick: () => void; statusText?: string|null }) => {
    const buttonCss = { fontSize: 'larger', margin: '0 10px', padding: '0 20px' }
    return (
        value ? (
            <div style={{ margin: '10px 0' }}>
                <CommonInputLabel label="AI商品説明">
                    <>
                        <div style={{ margin: '0 0 10px 0' }}>
                            <output style={OUTPUT_CSS}>
                                <MultilineComponent value={value} delimiter="\n" />
                            </output>
                            { statusText ? <div style={{ padding: '5px', fontSize: 'small', color: 'red' }}>{statusText}</div> : <></> }
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <Button variant="custom" style={buttonCss} onClick={onCopyClick}>コピー</Button>
                            { /*<Button variant="custom" style={buttonCss} onClick={onConfirmClick}>確定</Button>*/ }
                        </div>
                    </>
                </CommonInputLabel>
            </div>
        ) : <></>
    )
}
