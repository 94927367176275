export const copyToClipboard = (text: string) => {
    return navigator.clipboard.writeText(text)
}

export const getEnvironmentVariable = (key: string, options: { optional?: boolean ;} = {}) => {
    const error = () => {
        if (!options.optional) {
            throw new Error(`No environment variable: ${key}`)
        }
    }
    return process.env[key] || error()
}

export const isCurrentOrigin = (uri: string) => {
    try {
        return (new URL(uri)).origin === window.location.origin
    } catch (err) {
        return false
    }
}

export const preventResizeObserverLoopError = (e: ErrorEvent) => {
    if (e.message === 'ResizeObserver loop limit exceeded') {
    console.debug('ignoring ResizeObserver loop limit exceeded')
        const resizeObserverErrDiv = document.getElementById(
            'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
            'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
            resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
            resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
    }
}
