import { Badge, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { ServiceNotification } from "../../common/types"
import type { API } from "../api/API";

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

export const Notifications = ({ api }: { api: API }) => {
    const [notifications, setNotifications] = useState<ServiceNotification[]>([])
    const [open, setOpen] = useState<boolean>(false)

    useEffect(() => {
        api.getNotifications().then(notifications => {
            setNotifications(notifications)
        }).catch(error => {
            console.warn('getNotifications FAILURE', error)
            toast.error('通知一覧取得に失敗した。', error)
        })
    }, [])
    return (
        <>
            {
                notifications.length > 0 ? <IconButton aria-label="通知" onClick={() => setOpen(true)}>
                    <Badge badgeContent={notifications.length} variant="standard" color="primary" sx={{ overflow: 'unset!important' }}>
                        <NotificationsActiveIcon style={{ color: 'white' }} />
                    </Badge>
                </IconButton> : <></>
            }
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>
                    通知
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <ul style={{ listStyle: 'none', maxHeight: '80%', overflow: 'auto' }}>
                        {
                            notifications.map(notification => {
                                return (
                                    <li key={notification.id}>
                                        <Typography variant="h4">{notification.title}</Typography>
                                        <p>
                                            {notification.body}
                                        </p>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </DialogContent>
            </Dialog>
        </>
    )
}
