import { Button } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import type { API } from "../api/API";

export const LogoutButton = ({ iconOnly, onSuccess, onFailure, api, onLoggedOut }: { iconOnly?: boolean; onSuccess?: (message: string) => void; onFailure: (message: string, error?: Error) => void; api: API; onLoggedOut: () => void }) => {
    const logout = () => {
      return api.logout()
      .then(() => {
        if (onSuccess) {
          onSuccess('ログアウト成功')
        }
        onLoggedOut()
      })
      .catch((err) => {
        onFailure('ログアウト失敗', err)
      })
    }
    
    return (
      <Button title="ログアウト" variant="contained" style={{ backgroundColor: 'red', color: 'white', borderColor: 'white' }} onClick={logout}>
        { iconOnly ? <LogoutIcon /> : 'ログアウト' }
      </Button>
    )
  }
