import { AIQuery, ColorMePUTProductBody, ColorMeGETProductSuccessResponse, ColorMeGETProductsSuccessResponse, ColorMePUTProductSuccessResponse, Template, OpenAICompletionsSuccessResponse, ColorMeProductWithLinkedData, ServiceNotification, UserResponse } from '../../common/types'
import { getEnvironmentVariables, parseBooleanString, isMockLoggedIn } from '../helpers';

/**
 * サーバーは現在sessionを使っているため、Bearerトークンなどを設定する必要ない。
 * Error対応:
 * 4xx, 5xx はエラーとみなす。
 * 401の場合、ログアウトする。
 * そのほかのエラーは.catchなどで自分で対応すること。
 */
export class API {

    private base: string;
    private onLoggedOut: () => void;

    constructor({ base, onLoggedOut }: { base: string; onLoggedOut: () => void; }) {
        this.base = base;
        this.onLoggedOut = onLoggedOut;
    }

    async getProducts(): Promise<ColorMeGETProductsSuccessResponse> {
        const res = await this.fetch(this.r(`/products`), { method: 'GET' }); return await res.json()
    }
    
    // async getProduct(id: number): Promise<ColorMeGETProductSuccessResponse> {
    async getProduct(id: number): Promise<ColorMeProductWithLinkedData> {
        const res = await this.fetch(this.r(`/products/${id}`), { method: 'GET' }); return await res.json()
    }
    
    async updateProductDescription(id: number, query: AIQuery): Promise<ColorMePUTProductSuccessResponse> {
        const res = await this.fetch(this.r(`/products/${id}`), { method: 'PUT', body: JSON.stringify(query) }); return await res.json()
    }
    
    async queryServer(query: AIQuery, controller: AbortController): Promise<OpenAICompletionsSuccessResponse> {
        const res = await this.fetch(this.r(`/query`), { method: 'POST', body: JSON.stringify(query), signal: controller.signal }); return await res.json()
    }
    
    async getTemplates(): Promise<Template[]> {
        const res = await this.fetch(this.r(`/templates`), { method: 'GET' }); return await res.json()
    }

    async getNotifications(): Promise<ServiceNotification[]> {
        const res = await this.fetch(this.r(`/notifications`), { method: 'GET' }); return await res.json()
    }

    async checkIsLoggedIn(): Promise<boolean> {
        console.debug(getEnvironmentVariables(['MOCK_AUTH']))
        if (parseBooleanString(getEnvironmentVariables(['MOCK_AUTH']).MOCK_AUTH)) {
            return isMockLoggedIn();
        } else {
            const res = await this.fetch(this.r(`/auth-check`), { method: 'GET' }); return (await res.json()).loggedIn as boolean;
        }
    }

    async logout(): Promise<{ loggedOut: boolean }> {
        if (parseBooleanString(getEnvironmentVariables(['MOCK_AUTH']).MOCK_AUTH)) {
            return { loggedOut: true };
        } else {
            const res = await this.fetch(this.r(`/logout`), { method: 'POST' });
            // 強制的にlogoutができるよう、401は無視する。
            if (res.status === 401) {
                return { loggedOut: true }
            }
            return await res.json()
        }
    }

    async getUser(): Promise<UserResponse> {
        const res = await this.fetch(this.r(`/user`), { method: 'GET' }); return await res.json()
    }

    private parseRoute(route: string) {
        const base = `${this.base}`
        return `${base}${route}`
    }

    private r(route: string) {
        return this.parseRoute(route)
    }

    private async fetch(input: RequestInfo | URL, init?: RequestInit | undefined) {
        const options = init || {};
        if (!options.headers) options.headers = {}
        options.credentials = 'include'
        options.headers = { ...options.headers, ...(this.getCommonHeaders()) }

        const onError = () => {
            throw new Error('APIエラー')
        }

        try {
            const response = await fetch(input, options)
            if (response.status === 401) {
                await this.logout() // tokenを消すために。APIで行った方がよいが、401を返さないゆにしたら、これで対応できる。
                this.onLoggedOut()
            }
            if (!response.ok) {
                onError()
            }
            return response
        } catch (err) {
            throw new Error('APIエラー')
        }
    }

    private getCommonHeaders() {
        return {
            'Content-Type': 'application/json',
        }
    }
}
