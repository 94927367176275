export const MultilineComponent = ({ value, delimiter = '\n' }: { value: string; delimiter?: string }) => {
    return (
        <>
        {
            value.split(delimiter).map((item, index) => (
                <span key={index} style={{ display: 'inline-block' }}>
                    {item}<br />
                </span>
            ))
        }
        </>
    )
}
