import { getEnvironmentVariable } from "./utilities"

const ENVIRONMENT_VARIABLES = {
    // PORT: () => getEnvironmentVariable('PORT'),
    API_URL: () => getEnvironmentVariable('REACT_APP_API_URL'),
    OAUTH_URI: () => getEnvironmentVariable('REACT_APP_OAUTH_URI'), // ルート変更でも外部仕様でも可能ですので、別として定義しています。
    MOCK_API: () => getEnvironmentVariable('REACT_APP_MOCK_API', { optional: true }),
    MOCK_AUTH: () => getEnvironmentVariable('REACT_APP_MOCK_AUTH', { optional: true }),
    MOCK_AUTH_LOGGED_OUT: () => getEnvironmentVariable('REACT_APP_MOCK_AUTH_LOGGED_OUT', { optional: true }),
    PUBLIC_URL: ()  => getEnvironmentVariable('PUBLIC_URL', { optional: true }), // Routerなどが必要の場合。
}
export type EnvironmentVariable<T> = keyof T;
export type EnvironmentVariables<T> = Record<EnvironmentVariable<T>, string>;

/**
 * 状況によって必ず全部は必須でないため、必要のもののみを抽出できるようにつくっています。
 */
export function getEnvironmentVariables<T extends EnvironmentVariable<typeof ENVIRONMENT_VARIABLES>>(keys: (T)[]) {
    return Object.fromEntries(
        Object.entries(ENVIRONMENT_VARIABLES)
        .filter(([k]) => keys.includes(k as T))
        .map(([k, v]) => [k, v()])
    ) as Pick<EnvironmentVariables<typeof ENVIRONMENT_VARIABLES>, T>
}

export function parseBooleanString(str: string) {
    return str === 'true'
}

export const preventInfiniteReload = () => {
    const INFINITE_RELOAD_LAST_DATE_KEY = 'infinite_reload_date'
    const MIN_ALLOWED_INTERVAL = 1000
  
    const currentDate = Date.now()
    const lastReloadDateString = localStorage.getItem(INFINITE_RELOAD_LAST_DATE_KEY)
    if (lastReloadDateString && (currentDate - Number(lastReloadDateString) < MIN_ALLOWED_INTERVAL)) {
      throw new Error('無限reloadのため、エラー発火')
    }
    localStorage.setItem(INFINITE_RELOAD_LAST_DATE_KEY, `${currentDate}`)
}

export const isMockLoggedIn = () => {
    const { MOCK_AUTH_LOGGED_OUT } = getEnvironmentVariables(['MOCK_AUTH_LOGGED_OUT'])
    const loggedIn = (MOCK_AUTH_LOGGED_OUT !== 'true')
    return loggedIn
}
