import { Box, Typography } from "@mui/material"
import { toast } from "react-toastify"
import { LogoutButton } from "./LogoutButton"
import useMediaQuery from '@mui/material/useMediaQuery';
import { theme as myTheme } from '../theme'
import { Notifications } from "./Notifications";
import type { API } from "../api/API";
import { UserInfo } from "../../common/types";

export const Header = ({ api, user, onLoggedOut }: { api: API, user: UserInfo | null; onLoggedOut: () => void }) => {
    // const theme = useTheme();
    const theme = myTheme;
    const iconOnly = useMediaQuery(theme.breakpoints.down('sm'));
    const optimizeNotificationsLayout = useMediaQuery(theme.breakpoints.down('sm'));
    // TODO: flexモバイル、なぜかログアウトボタンの右側に余白あり、なんで？

    return (
    <header className="App-header">
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center',  justifyContent: 'center', transition: 'all 300ms ease', '> *': { padding: '10px', transition: 'all 300ms ease' } }}>
        <Box style={{ textAlign: 'center', ...(optimizeNotificationsLayout ? { flex: '3 0', display: 'none' } : { flex: '3 0' }) }}>
            <Notifications api={api} />
        </Box>
        <Box sx={{ flex: '6', textAlign: 'center' }}>
            <h1>誰でもエキスパート</h1>
        </Box>
        <Box sx={{ flex: optimizeNotificationsLayout ? 1 : '3 0', textAlign: 'right' }}>
            <Typography style={{ display: 'inline-block', margin: '0 10px' }}>
                { user ? user.username || '' : '' }
            </Typography>
            <LogoutButton iconOnly={iconOnly} onFailure={m => toast.error(m)} api={api} onLoggedOut={onLoggedOut} />
        </Box>
        </Box>
    </header>
    )
}
