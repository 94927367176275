import { Template } from "../../common/types";
import { TemplateSelectorItem } from "./TemplateSelectorItem";

/**
 * 幅を固定して、項目数を無限にするための仕様（必要に応じて導入）：
 * @see https://github.com/Learus/react-material-ui-carousel
 * @see https://learus.github.io/react-material-ui-carousel/
 * @see https://stackoverflow.com/questions/66861638/multiple-items-on-material-ui-carousel
 */
export const TemplateSelector = ({ selectedTemplate, templates, onChange }: { selectedTemplate: string|null; templates: Template[], onChange: (template: Template) => void }) => {
    return (
        <div style={{ display: 'flow-root' }}>
            {
                templates.map((template) => {
                    return <div key={template.id} onClick={() => onChange(template)} style={{ float: 'left' }}>
                        <TemplateSelectorItem selected={template.id === selectedTemplate} data={template} />
                    </div>
                })
            }
        </div>
    )
}
