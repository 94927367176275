import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from '@mui/material';

interface InputLabelProps {
    children: any;
    label: string;
}

const MobileCommonInputLabel = ({ children, label } : InputLabelProps) => {
    return (
        <>
            <p style={{ textAlign: 'left', fontSize: 'small', padding: '0px', margin: '0px' }}>{label}：</p>
            <hr />
            {children}
        </>
    )
}
const StandardCommonInputLabel = ({ children, label } : InputLabelProps) => {
    const flex = '1 1 150px'
    // const flex = '1 1 1'
    return (
        <>
            <div style={{ display: 'flex' }} >
                <div className='standard-common-input-label-wrapper' style={{ /*alignSelf: 'center',*/ flex, paddingTop: '10px', paddingRight: '10px', /* minWidth: '150px' */ }}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>{label}：</Typography>
                </div>
                <div style={{ flex: '7 7 500px' }}>
                    {children}
                </div>
            </div>
        </>
    )
}

export const CommonInputLabel = ({ children, label } : InputLabelProps) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        matches ? <StandardCommonInputLabel label={label}>{children}</StandardCommonInputLabel> : <MobileCommonInputLabel label={label}>{children}</MobileCommonInputLabel>
    )
}
