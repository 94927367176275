import { Box, Typography } from "@mui/material";
import { ColorMeProduct, ColorMeProductWithLinkedData } from "../../common/types";
import { parseColorMeProductInfo } from "../common/colorMeHelpers";

const DefinitionRow = ({ name, description }: { name: string; description: string; }) => (
    <span style={{ display: 'block' }}><dt>{name}</dt>：<dd>{description}</dd></span>
)

export const ProductInfo = ({ data, info }: { data: ColorMeProduct; info: ColorMeProductWithLinkedData | null }) => {
    const { name, description, options, priceText } = parseColorMeProductInfo(data)
    console.debug({ data, info, name })

    const Name = () => name ? <DefinitionRow name="商品名" description={name} /> : <></>
    const Description = () => description ? <DefinitionRow name="詳細" description={description} /> : <></>
    const Category = () => info?.category?.name ? <DefinitionRow name="ジャンル" description={info?.category?.name} /> : <></>
    const Price = () => priceText ? <DefinitionRow name="販売価格" description={priceText} /> : <></>
    const Option = (option: { name: string; values: string[] }) => <DefinitionRow name={option.name} description={ option.values.join('・') } />
    
    return (
        <Box style={{ display: 'block', clear: 'both', textAlign: 'left' }}>
            {/*<Typography sx={{ display: 'block' }} variant="h4">商品</Typography>*/}
            <dl style={{ display: 'block', clear: 'both' }}>
                <Name />
                {/*<Description />*/}
                <Category />
                <Price />
                {
                    options.map(option => <Option key={option.key} name={option.name} values={option.values} />)
                }
            </dl>
        </Box>
    )
}
