import { Typography } from "@mui/material"
import { TemplateSelector } from "./TemplateSelector"
import { Loader } from "./Loader"
import { Template } from "../../common/types";

export const TemplateSelectionContainer = ({ isLoadingTemplates, onTemplateChange, templateId, templates } : { isLoadingTemplates: boolean; templateId: string|null; onTemplateChange: (template: Template) => void; templates: Template[] }) => {
    return (
        <>
            <Typography variant="h6" style={{ textAlign: 'left' }}>好みのアシスタントを選んでください</Typography>
            {/*<div style={{ margin: '25px 0', textAlign: 'left' }}>*/}
                {/*<Typography variant="h6" style={{ display: 'inline-block' }}>【店員の設定】</Typography>*/}
                {/*<span style={{ padding: '2px 4px', marginLeft: '10px', color: 'black', backgroundColor: '#CC4444' }}>必須</span>*/}
                {/*!templateId ? <span style={{ color: 'red', paddingLeft: '10px' }}>＊ アシスタントを選んでください</span> : <></>*/}
                {/*<hr />*/}
            {/*</div>*/}
            {
                !isLoadingTemplates ? 
                <>
                    <TemplateSelector selectedTemplate={templateId} templates={templates} onChange={onTemplateChange} />
                </> : <Loader />
            }
        </>
    )
}
